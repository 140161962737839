<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
       
        <banner title="OFFICE STAFF" :breadcrumb="[
           {
              label: 'Dashboard',
            },
            {
              label: 'Staff',
            }
          ]">
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
      
            >
              <div class="card-body">
                <div class="row">
                  <div class="col text font">
                    <h4><b style="color: #00364f; font-weight: bolder">Staff </b></h4>
                  </div>
                  <div class="col text-end ">
                    <button
                      @click="formStaffNull()"
                      type="button"
                      style="background-color: #f21300"
                      class="btn btn-sm text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <strong>+ New</strong>
                    </button>
                    &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>

                <!-- start list area  -->
                <div class="row" style="margin-top:5px">
                 

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
            
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm t-font">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;</th>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Mobile No.</th>
                          <th>Email Id</th>
                          <th>Designation</th>
                          <th>Services</th>
                          <th class="text-end">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(staff, index) in allstaff"
                            :key="index"
                          >
                            <td>
                              <span v-if="staff.photo">
                                <img
                                  style="width: 30px; height: 30px"
                                  :src="
                                    this.$store.state.imgUrl +
                                    `/staff/${staff.id}/thumbs/${staff.photo}`
                                  "
                                  alt=""
                                />
                              </span>
                              <span v-else>
                                <img
                                  style="width: 30px; height: 30px"
                                  :src="this.$store.state.placeholderImg"
                                  alt=""
                                />
                              </span>
                            </td>
                            <td class="text-truncate">{{ staff.code }}</td>
                            <td class="text-truncate">{{ staff.name }}</td>
                            <td class="text-truncate">{{ staff.phone }}</td>
                            <td class="text-truncate">
                              {{ staff.email }}
                            </td>
                            <td class="text-truncate">
                              <span v-if="staff.designation">{{
                                staff.designation.name
                              }}</span>
                            </td>
                            <td class="text-center">
                              <button
                                type="button"
                                @click="getServices(staff)"
                                data-bs-toggle="modal"
                                data-bs-target="#linkservices"
                                class="btn text-white btn-sm"
                                style="
                                  background-color: rgb(242 19 1);
                                  padding: 5px 7px 5px 7px;
                                "
                              >
                                <b>
                                  <font-awesome-icon icon="link" />
                                </b>
                              </button>
                            </td>

                            <td class="text-truncate">
                              <div class="btn-group btn-group-sm" role="group">
                              
                                <router-link
                                  :to="`staff-view/${staff.id}`"
                                  type="button"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>
                                <button
                                  type="button"
                                  @click="staffEdit(staff)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  class="btn btns btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>
                                <button
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kycModal"
                                  @click="stafkycValueAsign(staff)"
                                  class="btn btns btn-sm text-dark"
                                  style="
                                    background-color: yellow;
                                    padding: 5px 4.5px 5px 4.5px;
                                  "
                                >
                                  <font-awesome-icon icon="file" />
                                </button>
                                <button
                                  v-if="staff.staff_kyc"
                                  @click="staffkycValueApprove(staff)"
                                  class="btn btns btn-sm"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #1426f5;
                                    color: white;
                                  "
                                  data-bs-toggle="modal"
                                  data-bs-target="#approoveKycModal"
                                >
                                  <b>
                                    <font-awesome-icon icon="tag" />
                                  </b>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadStaff(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadStaff(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadStaff(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadStaff(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadStaff(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadStaff(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadStaff(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadStaff(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadStaff(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadStaff( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadStaff( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadStaff(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadStaff(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadStaff(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadStaff(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadStaff(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
  <!-- modal for link Staff services services  -->

  <div
    class="modal fade"
    id="linkservices"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <b style="color: #00364f">Add staff Servicess</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm">
              <tr
                class="table-wrapper-scroll-y text-white rounded-circle"
                style="background-color: #00364f; height: 40px"
              >
                <th>+</th>
                <th>Name</th>
                <th class="text-end">Action</th>
              </tr>

              <tbody>
                <tr
                  style="color: #00364f"
                  v-for="(service, index) in services"
                  :key="index"
                >
                  <td>
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                  </td>

                  <td>{{ service.name }}</td>

                  <td class="text-end">
                    <div class="form-check">
                      <input
                        @click="staffServicestatus(service, $event)"
                        
                        type="checkbox"
                        :value="service.id"
                        v-model="stfService"
                      />
                    </div>
                  </td>

                  <!-- <td>
                    <div class="btn-group btn-group-sm" role="group">
                      <button  class="btn btns text-white btn-sm" @click="saveServicestaff(service)"
                        style="padding:5px 4.5px 5px 4.5px;background-color:#00364f;">
                        <font-awesome-icon icon="floppy-disk" />
                      </button>
                      <button type="button" class="btn btns btn-sm btn-danger" style="padding:5px 4.5px 5px 4.5px;">
                        <font-awesome-icon icon="trash" />
                      </button>
                       </div>
                  </td> -->
                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            data-bs-dismiss="modal"
            style="background-color: #f21000"
          >
            Close
          </button>
          <!-- <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #00364f"
          >
            Confirm
          </button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal t-font fade"
    id="exampleModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">OFFICE STAFF</strong>
          </h5>
          <button
          @click="loadStaff()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="edit == 'false' ? savestaff() : staffUpdate()">
            <div class="row">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <label for="file-input2">
                  <img style="width: 60px; height: 60px" :src="photoPreImg" />
                </label>
                <input
                  class="input-file"
                  @change="photoUpload"
                  style="position: absolute; top: -150000px"
                  id="file-input2"
                  type="file"
                  accept="image/*"
                />

                <div class="alert alert-danger p-1 mt-1" role="alert" v-if="msgImgError">
                  {{ msgImgError }}
                </div>
              </div>

              <!-- <div
                class="col-xl-3 col-md-3 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Code</label>
                  <input
                    type="tel"
                    v-model="staff_form.code"
                    class="form-control"
                    placeholder="Code"
                    style="height: 30px"
                  />
                  <small style="color: red" v-if="form.errors.error"
                    ><span v-if="form.errors.error.code">{{
                      form.errors.error.code[0]
                    }}</span></small
                  >
                </div>
              </div> -->
              <div
                class="col-xl-5 col-md-5 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Name <small style="color:#f21000">*</small></label>
                  <input
                    type="text"
                    v-model="staff_form.name"
                    class="form-control"
                    placeholder="staff name"
                    style="height: 30px;"
                    @change="lenthheker($event, 'professionalName')"
                    required
                  />
                  <span style="color: #f21000" id="professionalName"></span>
                </div>
              </div>
              <div
                class="col-xl-4 col-md-4 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Staff Designation <small style="color:#f21000">*</small></label>
                  <select
                    required
                    style="
                      height: 30px;
                    ;
                      padding: 5px 5px 5px 5px;
                    "
                    v-model="staff_form.designation_id"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option
                      v-for="(designation, index) in allDesignation"
                      :key="index"
                      :value="designation.id"
                    >
                      {{ designation.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-xl-4 col-md-4 col-sm-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Email <small style="color:#f21000">*</small></label>
                  <input
                    type="email"
                    v-model="staff_form.email"
                    class="form-control"
                    placeholder="Email "
                    style="height: 30px;"
                    required
                  />
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.email">{{
                            staff_form.errors.error.email[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
              <div
                class="col-xl-4 col-md-4 col-sm-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Phone Number <small style="color:#f21000">*</small></label>
                  <input
                    type="number"
                    v-model="staff_form.phone"
                    class="form-control"
                    placeholder="Phone "
                    style="height: 30px;"
                    @change="lenthheker($event, 'phonePro')"
                    required
                  />
                  <span style="color: #f21000" id="phonePro"></span>
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.phone">{{
                            staff_form.errors.error.phone[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
              <div
                class="col-xl-4 col-md-4 col-sm-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Alt. Number</label>
                  <input
                    type="number"
                    v-model="staff_form.alternativePhone"
                    class="form-control"
                    placeholder="Alternative Phone"
                    style="height: 30px;"
                    @change="lenthheker($event, 'altPhonePro')"
                  />
                  <span style="color: #f21000" id="altPhonePro"></span>
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.alternativePhone">{{
                            staff_form.errors.error.alternativePhone[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
              <div
                class="col-xl-3 col-md-3 col-sm-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Full Address <small style="color:#f21000">*</small></label>
                  <input
                    v-model="staff_form.address"
                    type="text"
                    class="form-control"
                    placeholder="Address "
                    style="height: 30px;"
                    @change="lenthheker($event, 'profesionalAddress')"
                  />
                  <span style="color: #f21000" id="profesionalAddress"></span>
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.address">{{
                            staff_form.errors.error.address[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
              <div
                class="col-xl-3 col-md-3 col-sm-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>City <small style="color:#f21000">*</small></label>
                  <input
                  required
                    type="text"
                    class="form-control"
                    v-model="staff_form.city"
                    placeholder="city "
                    style="height: 30px;"
                    @change="lenthheker($event, 'profesionalCity')"
                  />
                  <span style="color: #f21000" id="profesionalCity"></span>
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.city">{{
                            staff_form.errors.error.city[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
              <div
                class="col-xl-3 col-md-3 col-sm-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>State   <label>Name <small style="color:#f21000">*</small></label></label>
                  <select
                  required
                    style="
                      height: 30px;
                    ;
                      padding: 5px 5px 5px 5px;
                    "
                    v-model="staff_form.state_id"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option
                      v-for="(state, index) in states"
                      :key="index"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.state_id">{{
                            staff_form.errors.error.state_id[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
              <div
                class="col-xl-3 col-md-3 col-sm-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Pin Code <small style="color:#f21000">*</small></label>
                  <input
                    type="number"
                    v-model="staff_form.pin"
                    class="form-control"
                    placeholder=" pin code "
                    style="height: 30px;"
                    @change="lenthheker($event, 'pinCode')"
                  />
                  <span style="color: #f21000" id="pinCode"></span>
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.pin">{{
                            staff_form.errors.error.pin[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
              <div
                class="col-xl-3 col-md-3 col-sm-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>DOB <small style="color:#f21000">*</small></label>
                  <input
                  required
                    v-model="staff_form.dob"
                    placeholder="Date of Birth"
                    class="textbox-n form-control"
                    type="text"
                    onfocus="(this.type='date')"
                    id="date"
                    style="height: 30px;"
                    @change="dateCheker($event, 'doBDate')"
                  />
                  <span style="color: #f21000" id="doBDate"></span>
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.dob">{{
                            staff_form.errors.error.dob[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
              <div
                class="col-xl-3 col-md-3 col-sm-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Gender <small style="color:#f21000">*</small></label>
                  <select
                  required
                    v-model="staff_form.gender"
                    class="form-select"
                    aria-label="Default select example"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                  >
                    <option value="male" selected>MALE</option>
                    <option value="female">FEMALE</option>
                    <option value="other">OTHER</option>
                  </select>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-3 col-sm-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Department <small style="color:#f21000">*</small></label>
                  <select
                  required
                    v-model="staff_form.department_id"
                    class="form-select"
                    aria-label="Default select example"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                  >
                    <option
                      :value="depart.id"
                      v-for="(depart, index) in departments"
                      :key="index"
                    >
                      {{ depart.name }}
                    </option>
                  </select>
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.department_id">{{
                            staff_form.errors.error.department_id[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
              <div
                class="col-xl-3 col-md-3 col-sm-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Login Status</label>
                  <select
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="staff_form.loginStatus"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option value="true" selected>ACTIVE</option>
                    <option value="false">INACTIVE</option>
                  </select>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-3 col-sm-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Password</label>
                  <input
                    type="password"
                    v-model="staff_form.password"
                    class="form-control"
                    placeholder="Password"
                    style="height: 30px"
                    @change="lenthheker($event, 'proPassword')"
                  />
                  <span style="color: #f21000" id="proPassword"></span>
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.password">{{
                            staff_form.errors.error.password[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
              <div
                class="col-xl-12 col-md-12 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group">
                  <label>Remark</label>
                  <textarea
                    v-model="staff_form.remark"
                    class="form-control"
                    rows="3"
                    placeholder="Remark"
                    
                  ></textarea>
                  <small style="color: red" v-if="staff_form.errors"
                        ><span v-if="staff_form.errors.error"
                          ><span v-if="staff_form.errors.error.remark">{{
                            staff_form.errors.error.remark[0]
                          }}</span></span
                        ></small
                      > 
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
              @click="loadStaff()"
                type="button"
                class="btn text-white btn-sm"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn-sm btn text-white"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- end  modal -->

  <!-- Modal -->
  <div class="modal fade" id="kycModal"  tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text" id="exampleModalLabel">
            <strong>Staff Kyc</strong>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="staffKycVal == 'false' ? stafkycCreate() : staffUpdateKyc()"
          >
            <div class="alert alert-danger p-1" role="alert" v-if="msgImgError">
              {{ msgImgError }}
            </div>
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                <th>+</th>
                <th>Documents</th>
                <th>Action</th>
              </tr>
              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Staff Aadhaar Front <small style="color:#f21000">*</small></b>
                    <input
                      @change="uploadAdharFront"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="AdharFront"
                      type="file"
                      accept="image/*"
                    />
                    <input
                      type="number"
                      v-model="form.aadharNo"
                      class="form-control"
                      placeholder="Adhar Number"
                      style="height: 30px"
                      required
                      @change="lenthheker($event, 'professionalKycAddar')"
                    />
                    <span style="color: #f21000" id="professionalKycAddar"></span>
                    <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.aadharNo">{{
                          form.errors.error.aadharNo[0]
                        }}</span></span
                      ></small
                    >
                  </td>
                  <td class="text-truncate text-end">
                    <label for="AdharFront">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Staff Aadhaar Back</b>
                    <input
                      @change="uploadAdharBack"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="AdharBack"
                      type="file"
                      accept="image/*"
                    />
                  </td>
                  <td class="text-truncate text-end">
                    <label for="AdharBack">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Staff Pan <small style="color:#f21000">*</small></b>
                    <input
                      @change="uploadPanPhoto"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="panphoto"
                      type="file"
                      accept="image/*"
                    />

                    <input
                      v-if="panVarifyingStatus == 'true'"
                      disabled
                      type="tel"
                      maxlength="10"
                      v-model="form.panNo"
                      class="form-control"
                      placeholder="Pan Number"
                      style="height: 30px;"
                      required
                      @change="businessPanVeryfied()"
                      @keydown="nameKeydown($event)"
                    />
                    <input
                      v-else
                      type="tel"
                      maxlength="10"
                      v-model="form.panNo"
                      class="form-control"
                      placeholder="Pan Number"
                      style="height: 30px;"
                      required
                      @change="businessPanVeryfied()"
                      @keydown="nameKeydown($event)"
                    />
                    <span style="color: #f21000" id="adminPanVeryfied"></span>
                    <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.panNo">{{
                          form.errors.error.panNo[0]
                        }}</span></span
                      ></small
                    >
                  </td>
                  <td class="text-truncate text-end">
                    <label for="panphoto">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
              v-if="panVarifyingStatus == 'true'"
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
               <button
              v-else
                type="button"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal approoveKycModal-->

  <div
    class="modal fade"
    id="approoveKycModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <b class="text">Kyc Approve</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="table-wrapper-scroll-y text tr-head rounded-circle">
                <th>+</th>
                <th>Ducument Name</th>
                <th class="text-end">Download</th>
              </tr>

              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Staff Aadhaar Front&nbsp;&nbsp;</b>
                    <span
                      >{{ this.aadharNoForKyc }} {{ kycDoccInformation.aadharNo }}</span
                    >
                    <select
                      @change="aadharKycApprovedSave()"
                      class="form-select"
                      v-model="aadharapproveKyc.status"
                    >
                      <option value="approve">Approve</option>
                      <!-- <option value="reject">Reject</option> -->
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <!-- <label for="AdharFront"> -->
                    <button
                      @click="
                        extensionkycAdharFront.toLowerCase() == 'pdf'
                          ? openWindow(adharFrontImgPdf)
                          : openWindow(adharFrontImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Staff Aadhaar Back</b>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycAdharBack.toLowerCase() == 'pdf'
                          ? openWindow(adharBackImgPdf)
                          : openWindow(adharBackImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Staff Pan &nbsp;&nbsp;</b>
                    <span> {{ this.panNoForKyc }} {{ kycDoccInformation.panNo }}</span>
                    <select
                      v-model="panapproveKyc.status"
                      @change="panKycApprovedSave()"
                      class="form-select"
                    >
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycPanPhoto.toLowerCase() == 'pdf'
                          ? openWindow(panImgPdf)
                          : openWindow(panImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <!-- <img style="width: 50px; height: 50px" v-if="panImg" :src="panImg" /> -->
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Staff Kyc</b>
                    <select v-model="approveKyc.status" class="form-select">
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                    <br />
                    <textarea
                      v-model="approveKyc.remark"
                      rows="2"
                      class="form-control"
                      placeholder="Remark"
                    ></textarea>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      type="button"
                      @click="staffKycApprovedSave"
                      class="btn btns text-white btn-sm"
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    >
                      <font-awesome-icon icon="floppy-disk" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <!-- <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button> -->
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>



  
      <!-- Right Sidebar starts -->
      <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
  <li class="list-group-item">
    <div class="form-check">
  <input @click="valueNullFilter()" v-model="filterType" value="code" class="form-check-input" type="radio" name="flexRadioDefault" id="code">
  <label class="form-check-label" for="code">
    Code
  </label>
</div>
</li>
  <li class="list-group-item"><div class="form-check">
  <input @click="valueNullFilter()"  v-model="filterType" value="name" class="form-check-input" type="radio" name="flexRadioDefault" id="name">
  <label class="form-check-label" for="name">
    Name
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input @click="valueNullFilter()"  v-model="filterType" value="email" class="form-check-input"  type="radio" name="flexRadioDefault" id="email">
  <label class="form-check-label" for="email">
    email
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input @click="valueNullFilter()"  v-model="filterType"  value="phone" class="form-check-input"  type="radio" name="flexRadioDefault" id="phone">
  <label class="form-check-label" for="phone">
    phone
  </label>
</div></li>
<li class="list-group-item">
    <div class="form-check">
  <input @click="valueNullFilter()"  v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>


<div class="pt-1 pb-1">
  <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
 <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 <input required type="text" v-if="filterType != 'all' && filterType != 'phone'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 <input required type="number" v-if="filterType == 'phone'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 







</div>

<button  @click="loadStaff()" type="button" class="btn btn-success btn-sm">Success</button>



           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
var _ = require("lodash");
import Banner from "../../../components/super-admin/comman/Banner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "SuperAdminStaff",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      filterValue:'',
      filterType:'',
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      services: [],
      aadharNoForKyc: "",
      panNoForKyc: "",
      panImgPdf: "",
      adharFrontImgPdf: "",
      adharBackImgPdf: "",
      loading: false,
      extension: "",
      extensionProfile: "",
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      extensionkycPassbookPhoto: "",
      approveKyc: new Form({
        staff_id: null,
        type: "kyc",
        status: "cancel",
        remark: "",
      }),
      aadharapproveKyc: new Form({
        staff_id: null,
        type: "aadhar",
        status: "cancel",
        remark: "Aadhar Kyc",
      }),
      panapproveKyc: new Form({
        staff_id: null,
        type: "pan",
        status: "cancel",
        remark: "",
      }),
      accountapproveKyc: new Form({
        staff_id: null,
        type: "account",
        status: "cancel",
        remark: "",
      }),

      kycModalHeading: "Create Staff Kyc",
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      states: [],
      photoPreImg: this.$store.state.placeholderImg,
      allstaff: [],
      staff_form: {
        designation_id: null,
        department_id: null,
        photo: "",
        code: "",
        name: "",
        email: "",
        password: "",
        gender: "",
        phone: "",
        address: "",
        state_id: "",
        alternativePhone: "",
        remark: "",
        loginStatus: "",
        city: "",
        pin: null,
       errors:{}
      },
      edit: "false",
      staffKycVal: "false",
      allDesignation: [],
      staff_id: null,
      msgImgError: "",
      form: new Form({
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
        errors: {},
      }),
      staffServices: [],
      stfService: [],
      staff_services_form: {
        staff_id: null,
        status: "",
        service_id: null,
        remark: "",
      },
      departments: [],
      kycDoccInformation: {},
      professionalName: "false",
      doBDate: "false",
      professionalKycAddar: "false",
      phonePro: "false",
      altPhonePro: "false",
      profesionalAddress: "false",
      profesionalCity: "false",
      pinCode: "false",
      proPassword: "false",
      panVarifyingStatus: "false",
      submitStatus: "false",
    };
  },
  methods: {
   
    valueNullFilter(){
  this.filterValue = "";
  this.filterType = "";
},
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadStaff();
    },
    businessPanVeryfied() {
      var data = this.form.panNo;
      if (data.length == 10) {
        this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");
        // this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "superadmin/verifypan",
            { panNo: this.form.panNo },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            if (res.data.data.message) {
              // this.msgPanError = res.data.data.message;
              this.submitStatus = "true";
              $("#adminPanVeryfied").html("<small>" + res.data.data.message + "</small>");
            }
            if (res.data.data.data) {
              $("#adminPanVeryfied").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.full_name) {
              this.submitStatus = "false";
              $("#adminPanVeryfied").html("<small></small>");
              this.panVarifyingStatus = "true";
              // this.$swal.fire({
              //   position: "top-end",
              //   icon: "success",
              //   title: "Pan Verified Succesfully",
              //   showConfirmButton: false,
              //   timer: 1500,
              // });

              toast.success("Pan Verified Succesfully", {
              autoClose: 1000,
            });
            }
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            
              this.$swal.fire({
                position: "top-center",
                icon: "error",
                html: "<small>" + error.response.data.error + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });
            });
      } else {
        this.submitStatus == "true";
        $("#adminPanVeryfied").html("<small> Pan required 10 Digits</small>");
      }

      // this.panNo = ''
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        $("#adminPanVeryfied").html("<small>special characters are not allowed</small>");
        e.preventDefault();
      } else {
        $("#adminPanVeryfied").html("<small></small>");
      }
    },
    dateCheker(event, type) {
      var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      var data = event.target.value;

      if (type == "doBDate") {
        if (currentDateWithFormat < data) {
          this.doBDate = "true";

          $("#doBDate").html("<small>It should be less than current date</small>");
        }
        if (currentDateWithFormat >= data) {
          this.doBDate = "false";

          $("#doBDate").html("<small></small>");
        }
      }
      // }
    },

    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "professionalKycAddar") {
        if (data.length != 12) {
          this.professionalKycAddar = "true";

          $("#professionalKycAddar").html(
            "<small>Aadhar required minimum 12 letters</small>"
          );
        }
        if (data.length == 12) {
          this.professionalKycAddar = "false";
          $("#professionalKycAddar").html("<small></small>");
        }
      }
      if (type == "professionalName") {
        if (data.length < 3) {
          this.professionalName = "true";
          $("#professionalName").html("<small>Name required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.professionalName = "false";
          $("#professionalName").html("<small></small>");
        }
      }

      if (type == "phonePro") {
        if (data.length != 10) {
          
          this.phonePro = "true";
          $("#phonePro").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.phonePro = "false";
          $("#phonePro").html("<small></small>");
        }
      }
      if (type == "altPhonePro") {
        if (data.length != 10) {
              if(data.length == 0){
                this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
              }else{
                this.altPhonePro = "true";
          $("#altPhonePro").html("<small>Alt Phone Number 10 Digit Required</small>");
              }
          

         
        }
        if (data.length == 10) {
          this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
        }
      }

      if (type == "profesionalAddress") {
        if (data.length < 3) {
          this.profesionalAddress = "true";
          $("#profesionalAddress").html(
            "<small>Address required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.profesionalAddress = "false";
          $("#profesionalAddress").html("<small></small>");
        }
      }
      if (type == "profesionalCity") {
        if (data.length < 3) {
          this.profesionalCity = "true";
          $("#profesionalCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.profesionalCity = "false";
          $("#profesionalCity").html("<small></small>");
        }
      }

      if (type == "pinCode") {
        if (data.length != 6) {
          this.pinCode = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }

      if (type == "proPassword") {
        if (data.length < 6) {

          if(data.length == 0){
            this.proPassword = "false";
          $("#proPassword").html("<small></small>");
          }else{
              this.proPassword = "true";
          $("#proPassword").html("<small>Password Minimum 6 Digits Required</small>");
          }
        
        }
        if (data.length >= 6) {
          this.proPassword = "false";
          $("#proPassword").html("<small></small>");
        }
      }
    },
    openWindow(url) {
      window.open(`${url}`);
    },
    formStaffNull() {
      this.staff_form = {};
      this.photoPreImg = this.$store.state.placeholderImg;
      this.staff_form.gender = "male";
      this.staff_form.state_id = 1;
      this.staff_form.loginStatus = "true";
      this.professionalName == "false";
      this.doBDate == "false";
      this.phonePro == "false";
      this.altPhonePro == "false";
      this.profesionalAddress == "false";
      this.profesionalCity == "false";
      this.proPassword == "false";
      this.pinCode == "false";
      this.msgImgError == "";

      $("#proPassword").html("<small></small>");
      $("#pinCode").html("<small></small>");
      $("#profesionalCity").html("<small></small>");
      $("#profesionalAddress").html("<small></small>");
      $("#altPhonePro").html("<small></small>");
      $("#professionalName").html("<small></small>");
      $("#phonePro").html("<small></small>");
      $("#professionalKycAddar").html("<small></small>");

      this.edit = "false";
    },
    getServices(staff) {
      this.staff_id = staff.id;
      this.staff_services_form.staff_id = staff.id;
      this.loadServices();
      this.staffServicesLoad();
    },
    staffServicestatus(service, event) {

   
console.log(event.target.checked)

      if (event.target.checked == true) {
        this.staff_services_form.status = "true";
      } else if (event.target.checked == false) {
        this.staff_services_form.status = "false";
      }
      var service_info = service;
      this.saveServicestaff(service_info);
    },
    
    saveServicestaff(service) {
      this.staff_services_form.service_id = service.id;
      // this.staff_services_form.status = "false";
      // for (var i = 0; i < this.stfService.length; i++) {
      //   if (service.id == this.stfService[i]) {
      //     this.staff_services_form.status = "true";
      //   }
      // }


    

      var usrStr = "superadmin/staffservice";
      if (this.staff_services_form.status == "false") {
        usrStr = usrStr + "/" + service.staff_service_id;
        this.loading = true;
        this.$axios
          .delete(usrStr, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            this.loadServices()
           
            toast.success("Staff service Delete Succesfully", {
              autoClose: 1000,
            });
            
            // this.staffServicesLoad()
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });

      } else {
        this.loading = true;
        this.$axios
          .post(usrStr, this.staff_services_form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;

            toast.success("Staff service Created Succesfully", {
              autoClose: 1000,
            });
            this.loadServices()
          
          }).finally(() => (this.loading = false));
      }
    },
    staffServicesLoad() {
      this.$axios
        .get(`/superadmin/staffservice?all=all&staff_id=${this.staff_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.staffServices = res.data.data;

          this.stfService = [];
          var serModule = this.services;
var ids = []
_.forEach(this.staffServices, function (value) {
  if (
    _.find(serModule, function (o) {
      return o.id == value.service_id;
    })
  ) {
    var index = _.findIndex(serModule, function (o) {
      return o.id == value.service_id;
    });
    serModule[index].status = value.status;
    serModule[index].staff_service_id =value.id;
    serModule[index].remark = value.remark;
    ids.push(value.service_id);
  }
});
this.stfService = ids
this.services = serModule;


        });
    },
    staffkycValueApprove(value) {
      this.approveKyc.staff_id = value.id;
       this.approveKyc.remark = value.staff_kyc.remark;
      this.approveKyc.status = value.staff_kyc.kycStatus;
      this.accountapproveKyc.staff_id = value.id;
      this.panapproveKyc.staff_id = value.id;
      this.panapproveKyc.status = value.staff_kyc.panStatus;
      this.aadharapproveKyc.staff_id = value.id;
      this.aadharapproveKyc.status = value.staff_kyc.aadharStatus;
      this.kycDoccInformation = value.staff_kyc;

      if (value.staff_kyc.panPhoto != null) {
        // this.panImg = this.$store.state.imgUrl + '/staff/' + value.id + '/thumbs/' + value.staff_kyc.panPhoto

        if (
          this.$store.state.imgUrl +
          "/staff/" +
          value.id +
          "/thumbs/" +
          value.staff_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/thumbs/" +
            value.staff_kyc.panPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/staff/" +
          value.id +
          "/" +
          value.staff_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/" +
            value.staff_kyc.panPhoto;
        }
        this.panImgPdf = this.panImg;
        this.extensionkycPanPhoto = this.panImg.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

      if (value.staff_kyc.aadharFrontPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/staff/" +
          value.id +
          "/thumbs/" +
          value.staff_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/thumbs/" +
            value.staff_kyc.aadharFrontPhoto;
        }

        if (
          this.$store.state.imgUrl +
          "/staff/" +
          value.id +
          "/thumbs/" +
          value.staff_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/" +
            value.staff_kyc.aadharFrontPhoto;
        }

        this.adharFrontImgPdf = this.adharFrontImg;
        this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharFrontImg = this.$store.state.placeholderImg;
      }

      if (value.staff_kyc.aadharBackPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/staff/" +
          value.id +
          "/thumbs/" +
          value.staff_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/thumbs/" +
            value.staff_kyc.aadharBackPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/staff/" +
          value.id +
          "/" +
          value.staff_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/" +
            value.staff_kyc.aadharBackPhoto;
        }

        this.adharBackImgPdf = this.adharBackImg;
        this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharBackImg = this.$store.state.placeholderImg;
      }

      // kjdfhgkdfhk
    },
    staffKycApprovedSave() {
      var kyc;
      if (this.approveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.approveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.approveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/approvestaffkyc", this.approveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Staff Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
          this.form = {};
          this.loadStaff();
          //    this.$router.push({ name: 'Admins' })
        })
        .finally(() => (this.loading = false));
    },
    aadharKycApprovedSave() {
      var kyc;
      if (this.aadharapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.aadharapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.aadharapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/approvestaffkyc", this.aadharapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Aadhar Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
          //    this.$router.push({ name: 'Admins' })
        })
        .finally(() => (this.loading = false));
    },
    accountKycApprovedSave() {
      var kyc;
      if (this.accountapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.accountapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.accountapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/approvestaffkyc", this.accountapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Bank Account Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
          //    this.$router.push({ name: 'Admins' })
        })
        .finally(() => (this.loading = false));
    },
    panKycApprovedSave() {
      var kyc;
      if (this.panapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.panapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.panapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/approvestaffkyc", this.panapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Pan Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
          //    this.$router.push({ name: 'Admins' })
        })
        .finally(() => (this.loading = false));
    },

    photoUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Profile Image Size Too Big 2MB";
        return false;
      } else {
        var fileName;

        fileName = document.querySelector("#file-input2").value;
        this.extensionProfile = fileName.split(".").pop();
        if (
          this.extensionProfile == "png" ||
          this.extensionProfile == "jpeg" ||
          this.extensionProfile == "jpg" ||
          this.extensionProfile == "PNG" ||
          this.extensionProfile == "JPEG" ||
          this.extensionProfile == "JPG" 
        ) {
          this.photoPreImg = URL.createObjectURL(e.target.files["0"]);
          this.staff_form.photo = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG , PNG, JPEG";
        }
      }
    },

    uploadAdharFront(e) {
      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#AdharFront").value;
      this.extensionkycAdharFront = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
           this.extensionkycAdharFront == "png" ||
          this.extensionkycAdharFront == "jpeg" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "PNG" ||
          this.extensionkycAdharFront == "JPEG" ||
          this.extensionkycAdharFront == "JPG" ||
          this.extensionkycAdharFront == "PDF"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharFrontPhoto = e.target.files["0"];

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Aadhar Front File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    uploadAdharBack(e) {
      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#AdharBack").value;
      this.extensionkycAdharBack = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
          this.extensionkycAdharBack == "png" ||
          this.extensionkycAdharBack == "jpeg" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "PNG" ||
          this.extensionkycAdharBack == "JPEG" ||
          this.extensionkycAdharBack == "JPG" ||
          this.extensionkycAdharBack == "PDF"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharBackPhoto = e.target.files["0"];

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Aadhar Front File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },

    uploadPanPhoto(e) {
      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#panphoto").value;
      this.extensionkycPanPhoto = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
           this.extensionkycPanPhoto == "png" ||
          this.extensionkycPanPhoto == "jpeg" ||
          this.extensionkycPanPhoto == "jpg" ||
          this.extensionkycPanPhoto == "pdf" ||
          this.extensionkycPanPhoto == "PNG" ||
          this.extensionkycPanPhoto == "JPEG" ||
          this.extensionkycPanPhoto == "JPG" ||
          this.extensionkycPanPhoto == "PDF"
        ) {
          this.panImg = URL.createObjectURL(e.target.files["0"]);
          this.form.panPhoto = e.target.files["0"];

          if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Aadhar Front File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    stafkycValueAsign(value) {
      this.form = {};
      this.form.errors = {};
      
      this.submitStatus == "false";
        $("#adminPanVeryfied").html("<small></small>");


        this.professionalKycAddar = "false";
          $("#professionalKycAddar").html("<small></small>");
      this.adharBackImg = this.$store.state.placeholderImg;
      this.adharFrontImg = this.$store.state.placeholderImg;

      this.panImg = this.$store.state.placeholderImg;
      this.staff_id = value.id;

      if (value.staff_kyc == null) {
        this.staffKycVal = "false";
        this.panVarifyingStatus = "false";
      } else {
        this.panVarifyingStatus = "true";
        this.staffKycVal = "true";
        this.form = value.staff_kyc;
        if (value.staff_kyc.panPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/thumbs/" +
            value.staff_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/staff/" +
              value.id +
              "/thumbs/" +
              value.staff_kyc.panPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/" +
            value.staff_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/staff/" +
              value.id +
              "/" +
              value.staff_kyc.panPhoto;
          }

          this.extensionkycPanPhoto = this.panImg.split(".").pop();

          if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.panImg = this.$store.state.placeholderImg;
        }

        if (value.staff_kyc.aadharFrontPhoto != null) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/thumbs/" +
            value.staff_kyc.aadharFrontPhoto;
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/" +
            value.staff_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/staff/" +
              value.id +
              "/" +
              value.staff_kyc.aadharFrontPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/thumbs/" +
            value.staff_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/staff/" +
              value.id +
              "/thumbs/" +
              value.staff_kyc.aadharFrontPhoto;
          }

          this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

          if (this.extensionkycAdharFront == "pdf"  || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharFrontImg = this.$store.state.placeholderImg;
        }

        if (this.form.aadharBackPhoto != null) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/thumbs/" +
            value.staff_kyc.aadharBackPhoto;
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/" +
            value.staff_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/staff/" +
              value.id +
              "/" +
              value.staff_kyc.aadharBackPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/staff/" +
            value.id +
            "/thumbs/" +
            value.staff_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/staff/" +
              value.id +
              "/thumbs/" +
              value.staff_kyc.aadharBackPhoto;
          }

          this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharBackImg = this.$store.state.placeholderImg;
        }
      }
      //   this.kycModalHeading = "Staff Kyc";
      //   this.form = value.staff_kyc;
    },
    stafkycCreate() {
      if (this.professionalKycAddar == "false" && this.msgImgError == "") {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("staff_id", this.staff_id);
        this.loading = true;
        this.$axios
          .post("superadmin/staffkyc", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Kyc Created Succesfully", {
              autoClose: 1000,
            });
            this.form = {};
            this.form.errors = {};
            this.loadStaff();
            $("#kycModal").modal("hide");
          })
          .catch((error) => {
            this.form.errors = error.response.data;

          })

          .finally(() => (this.loading = false));
      }
    },
    staffUpdateKyc() {
      if (this.professionalKycAddar == "false" && this.msgImgError == "") {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("staff_id", this.staff_id);
        this.loading = true;
        this.$axios
          .post(`superadmin/staffkyc/${this.form.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Kyc Updated Succesfully", {
              autoClose: 1000,
            });
            this.form = {};
            this.form.errors = {};
            this.loadStaff();
            $("#kycModal").modal("hide");
          })
          .catch((error) => {
            this.form.errors = error.response.data;
          })

          .finally(() => (this.loading = false));
      }

      // }
    },
    staffEdit(staff) {

      console.log(staff);
      this.professionalName == "false";
      this.phonePro == "false";
      this.altPhonePro == "false";
      this.profesionalAddress == "false";
      this.profesionalCity == "false";
      this.proPassword == "false";
      this.pinCode == "false";
      this.msgImgError == "";
      this.doBDate == "false";

      $("#doBDate").html("<small></small>");
      $("#proPassword").html("<small></small>");
      $("#pinCode").html("<small></small>");
      $("#profesionalCity").html("<small></small>");
      $("#profesionalAddress").html("<small></small>");
      $("#altPhonePro").html("<small></small>");
      $("#professionalName").html("<small></small>");
      $("#phonePro").html("<small></small>");
      $("#professionalKycAddar").html("<small></small>");

      this.staff_id = staff.id;
      this.staff_form.staff_type_id = staff.staff_type_id;
      this.staff_form = staff;
      this.staff_form.gender = staff.gender;

      if (staff.photo != null) {
        this.photoPreImg =
          this.$store.state.imgUrl + "/staff/" + staff.id + "/thumbs/" + staff.photo;
      } else {
        this.photoPreImg = this.$store.state.placeholderImg;
      }
      this.edit = "true";
      this.staff_form.photo = "";
    },
    staffUpdate() {
      if (
        this.professionalName == "false" &&
        this.phonePro == "false" &&
        this.altPhonePro == "false" &&
        this.profesionalAddress == "false" &&
        this.profesionalCity == "false" &&
        this.proPassword == "false" &&
        this.pinCode == "false" &&
        this.doBDate == "false" &&
        this.msgImgError == ""
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.staff_form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.staff_form[keys[n]] !== null) {
            formData.append(keys[n], this.staff_form[keys[n]]);
          }
        }

        this.$axios
          .post(`superadmin/staff/${this.staff_form.id}?_method=put`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Staff Updated  Succesfully", {
              autoClose: 1000,
            });
            this.staff_form = {};
            this.staff_form.errors = {};
            this.photoPreImg = this.$store.state.placeholderImg;
            this.loadStaff();
            $("#exampleModal").modal("hide");
          })
          .catch((error) => {
            this.staff_form.errors = error.response.data;
          });
        //  .finally(() => (this.loading = false))
      }
    },
    savestaff() {
      if (
        this.professionalName == "false" &&
        this.phonePro == "false" &&
        this.altPhonePro == "false" &&
        this.profesionalAddress == "false" &&
        this.profesionalCity == "false" &&
        this.proPassword == "false" &&
        this.pinCode == "false" &&
        this.doBDate == "false" &&
        this.msgImgError == ""
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.staff_form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.staff_form[keys[n]] !== null) {
            formData.append(keys[n], this.staff_form[keys[n]]);
          }
        }
        // this.loading = true
        this.$axios
          .post("superadmin/staff", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("New Staff Created  Succesfully", {
              autoClose: 1000,
            });
            this.staff_form = {};
            this.staff_form.errors = {};
            this.photoPreImg = this.$store.state.placeholderImg;
            this.loadStaff();
            $("#exampleModal").modal("hide");
          })
          .catch((error) => {
            this.staff_form.errors = error.response.data;
          });
      }

      // .finally(() => (this.loading = false))
    },
    loadServices() {
      this.loading = true;
      this.$axios
        .get("superadmin/service?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.services = res.data.data;
          this.staffServicesLoad();
        })
        .finally(() => (this.loading = false));
    },

    loadStaff(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
    


        if( this.filterType == 'all'){
            this.filterValue = ''
            this.filterType = ''
          }


          if(this.filterValue != null && this.filterType != 'all'){
           

            if(this.filterType == 'name'){

              pageUrl += `superadmin/staff?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
                      }else{
                        pageUrl += `superadmin/staff?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          
                      }




           }else{
            pageUrl += `superadmin/staff?per_page=${this.per_page}`;
           }

      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.allstaff = res.data.data.data;
          this.pagination = res.data.data;
          this.filterValue  = ""
        })
        .finally(() => (this.loading = false));
    },
    allDesignationLoad() {
      this.loading = true;
      this.$axios
        .get("superadmin/designation?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.allDesignation = res.data.data;
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          error;
        });
    },
    loadDepartments() {
      this.$axios
        .get("superadmin/department?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.departments = res.data.data;
        });
    },
    loadStates() {
      this.$axios
        .get("superadmin/state?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.states = res.data.data;
        });
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.staff
    console.log(this.$store.state.superAdminPageTitles.staff)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadStaff();
      this.loadServices();
      this.loadStates();
      this.allDesignationLoad();
      this.loadDepartments();
            
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}
.t-font {
  font-family: "Poppins", sans-serif;
}
.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

input:checked {
  /* background-color: #7367f0; */
  border-color: #ff000c;
  border-width: 5px;
}
.form-check-input:checked {
  background-color: #fdfdfd;
  /* border-color: #7367f0; */
}
.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}




</style>
